<template>
      <HeaderComponent />
  <div class="comparison-card">
    <!-- Card de comparação -->
    <div class="checkout-card">
      <h2>PDF Completo para obter o visto de nômade digital da Espanha</h2>
      <table>
        <tr class="header-row">
          <th></th>
          <th class="plan">
            <p>Apenas R$99,90</p>
          </th>
        </tr>
        <tr class="feature">
          <td>Passo a Passo para Solicitar o Visto de Nômade Digital</td>
          <td><i class="fas fa-check green-icon"></i></td>
        </tr>
        <tr class="feature">
          <td>Modelos e Formulários</td>
          <td><i class="fas fa-check green-icon"></i></td>
        </tr>
        <tr class="feature">
          <td>Orientações Valiosas e Dicas Úteis</td>
          <td><i class="fas fa-check green-icon"></i></td>
        </tr>
        <tr class="feature">
          <td>Documentação Necessária</td>
          <td><i class="fas fa-check green-icon"></i></td>
        </tr>
        <tr class="feature">
          <td>Cronograma e Prazo para Solicitação</td>
          <td><i class="fas fa-check green-icon"></i></td>
        </tr>
        <tr class="feature">
          <td>Consultoria em vídeo</td>
          <td><i class="fas fa-times red-icon"></i></td>
        </tr>
      </table>
      <button @click="startCheckout" class="button">Compre agora!</button>
    </div>
  </div>
</template>

<script>
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
import HeaderComponent from '@/components/HeaderComponent.vue'; 


export default {
  name: 'ComparisonPage',
  components: {
    HeaderComponent,
  },
  methods: {
    async startCheckout() {
      // Carrega a chave pública do Stripe
      const stripe = await loadStripe('pk_live_51NjqO9A4UOAoUwAhqLKdDNh96v1lFEOAqd1U6K9lciqcITqVpaX47ynh1HyLXzN5h9k02Ks01TAHcnl0Tp65VXII00LN6vZla0');
      
      // Solicita ao servidor para criar uma sessão de checkout
      try {
        const response = await axios.post(
          process.env.VUE_APP_POST_STRIPE_URL,
          {},
          {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem("token")}`
            }
          }
        );

        const session = response.data;

        // Inicia o processo de checkout redirecionando o usuário para a página de pagamento do Stripe
        const result = await stripe.redirectToCheckout({
          sessionId: session.sessionId,
        });

        // Trata erros, se houver
        if (result.error) {
          console.error(result.error.message);
        }
      } catch (error) {
        console.error("Erro ao criar sessão de checkout:", error);
      }
    },
  },
};
</script>

<style>
.comparison-card {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh;
}

.checkout-card {
  text-align: center;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  padding: 20px;
  border-radius: 10px;
  background-color: #f9f9f9;
  max-width: 900px;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.header-row th {
  font-weight: bold;
  padding: 10px;
  text-align: center;
  border-bottom: 1px solid #ddd;
}

.plan {
  text-align: center;
}

.feature td {
  padding: 10px;
  text-align: center;
  border-bottom: 1px solid #ddd;
}

i {
  margin-left: 10px;
}

.button {
  background-color:green;
  color: #fff;
  border: none;
  padding: 10px 80px;
  cursor: pointer;
  font-size: 14px;
  border-radius: 5px;
  margin-top: 1rem;
}

.green-icon {
  color: green;
}

.red-icon{
color: red;}

@media (max-width: 600px) {
  .checkout-card {
  margin-top: 15rem;
  text-align: center;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  padding: 20px;
  border-radius: 10px;
  max-width: 900px;
}
.comparison-card {
  display: flex;
  justify-content: center;
  height: 60vh;
}
}

.botton-text{
  font-size: 0.8rem;
}

</style>