<template>
    <div class="reset-password-container">
      <h2>Troca de Senha</h2>
  
      <!-- Etapa 1: Solicitar troca de senha -->
      <div v-if="step === 1">
        <div class="input-group">
          <label for="email">E-mail:</label>
          <input type="email" id="email" v-model="email" required>
        </div>
        
        <button @click="requestResetCode">Solicitar troca de senha</button>
      </div>
  
      <!-- Etapa 2: Inserir código de verificação -->
      <div v-else-if="step === 2">
        <div class="input-group">
          <label for="verificationCode">Código de Verificação:</label>
          <input type="text" id="verificationCode" v-model="verificationCode" required>
        </div>
  
        <button @click="verifyCode">Verificar Código</button>
      </div>
  
      <!-- Etapa 3: Definir nova senha -->
      <div v-else-if="step === 3">
        <div class="input-group">
          <label for="newPassword">Nova Senha:</label>
          <input type="password" id="newPassword" v-model="newPassword" required>
        </div>
  
        <button @click="resetPassword">Redefinir Senha</button>
      </div>
    </div>
    <div v-if="isLoading" class="loading-overlay">
      <div class="loading-icon"></div>
      <p>Enviando o código...</p>
    </div>
</template>
  
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        step: 1,
        email: '',
        verificationCode: '',
        newPassword: '',
        isLoading: false,
      };
    },
    methods: {
      async requestResetCode() {
        try {
        this.isLoading = true; 
          // Chamar a API para solicitar o código de verificação com o e-mail fornecido
          await axios.post(process.env.VUE_APP_POST_RESETPASS_RESQUEST_API_URL, { email: this.email });
  
          // Avance para a próxima etapa
          this.step = 2;
        } catch (error) {
          console.error(error);
        } finally {
        this.isLoading = false; // Desativar o componente de carregamento
      }
      },
      async verifyCode() {
        try {
          // Chamar a API para verificar o código de verificação inserido pelo usuário
          await axios.post(process.env.VUE_APP_POST_RESETPASS_VERIFY_API_URL, {
            email: this.email,
            verification: this.verificationCode
          });
  
          // Avance para a próxima etapa
          this.step = 3;
        } catch (error) {
          console.error(error);
        }
      },
      async resetPassword() {
        try {
          // Chamar a API para redefinir a senha usando o novo valor fornecido
          await axios.post(process.env.VUE_APP_POST_RESETPASS_UPDATE_API_URL, {
            email: this.email,
            password: this.newPassword
          });
  
          // Redirecionar para a página de login após redefinir a senha
          this.$router.push('/login');
        } catch (error) {
          console.error(error);
        }
      }
    }
  };
  </script>

 <style scoped>
 .reset-password-container {
   width: 500px;
   margin: 0 auto;
   padding: 50px;
   box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
   border-radius: 8px;
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   background-color: #ffffff;
 }
 
 h2 {
   text-align: center;
   margin-bottom: 30px;
   font-size: 24px;
   color: #333;
 }
 
 .input-group {
   margin-bottom: 20px;
 }
 
 label {
   display: block;
   margin-bottom: 10px;
   color: #555;
 }
 
 input {
   width: 100%;
   padding: 10px;
   border: 1px solid #ccc;
   border-radius: 4px;
   color: #333;
   font-size: 16px;
   transition: border-color 0.3s ease;
 }
 
 input:focus {
   border-color: #007bff;
   outline: none;
 }
 
 button {
   width: 100%;
   padding: 10px;
   background-color: #007bff;
   color: #fff;
   border: none;
   border-radius: 4px;
   font-size: 18px;
   cursor: pointer;
   transition: background-color 0.3s ease;
 }
 
 button:hover {
   background-color: #0056b3;
 }

 .loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.loading-icon {
  width: 40px;
  height: 40px;
  border: 4px solid transparent;
  border-top-color: #007bff; /* Cor de realce do botão */
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.loading-overlay p {
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 1rem;
}


@keyframes fadeInOut {
  0% {
    opacity: 0;
    transform: translateY(100%) translateX(-50%);
  }
  10%, 90% {
    opacity: 1;
    transform: translateY(0) translateX(-50%);
  }
  100% {
    opacity: 0;
    transform: translateY(100%) translateX(-50%);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Estilos para dispositivos móveis */
@media screen and (max-width: 600px) {
  .reset-password-container {
    width: 90%; /* Reduz a largura do container */
    padding: 20px; /* Reduz o padding */
  }
  
  h2 {
    font-size: 20px; /* Reduz o tamanho da fonte */
    margin-bottom: 20px; /* Reduz a margem inferior */
  }

  .input-group {
    margin-bottom: 15px; /* Reduz a margem inferior */
  }

  input {
    font-size: 14px; /* Reduz o tamanho da fonte */
    padding: 8px; /* Reduz o padding */
  }

  button {
    font-size: 16px; /* Reduz o tamanho da fonte */
    padding: 8px; /* Reduz o padding */
  }
}
 </style>
 