import { createStore } from 'vuex';

const store = createStore({
  state: {
    username: localStorage.getItem('username') || null
  },
  mutations: {
    setUsername(state, username) {
      state.username = username;
      localStorage.setItem('username', username); // Adicione esta linha para salvar o username no localStorage toda vez que ele for alterado.
    }
  }
});

export default store;
