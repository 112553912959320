import { createRouter, createWebHistory } from 'vue-router';
// import HomePage from './views/HomePage.vue';
import UserLogin from './components/UserLogin.vue';
import UserRegister from './components/UserRegister.vue';
import PublicPage from  './views/PublicPage.vue';
// import FlashcardPage from './views/FlashcardPage.vue';
import AccountLayout from './views/AccountLayout.vue';
import ProfileCompletion from './components/ProfileCompletion.vue';
import ResetPassword from './views/ResetPassword.vue';
import CheckoutPage from './views/CheckoutPage.vue';
// import FlashcardSet from './components/FlashcardSet.vue';
// import MyRotes from './views/MyRotes.vue';
// import ViewItinerary from './components/ViewItinerary.vue';
// import StudentArea from './views/StudentArea.vue';
// import RedacaoVerify from './views/RedacaoVerify.vue';
// import CreateEssay from './components/CreateEssay.vue';
// import ListEssay from './components/ListEssay.vue';
// import EssayCatalog from './views/EssayCatalog.vue';
// import AccessSharedFolder from './components/AccessSharedFolder.vue';
import DownloadPage from './components/DownloadPage.vue';



// Função de guarda de rota
function requireAuth(to, from, next) {
  if (localStorage.getItem('token')) {
    next(); // permite acessar a rota
  } else {
    // Armazenar a rota desejada antes de redirecionar para o login
    localStorage.setItem('preLoginRoute', to.fullPath);
    next('/login'); // redireciona para a página de login
  }
}

const routes = [
  // {
  //   path: '/memo',
  //   name: 'home',
  //   component: HomePage,
  //   beforeEnter: requireAuth // protege a rota inicial, como exemplo
  // },
  {
    path: '/login',
    name: 'login',
    component: UserLogin
  },
  {
    path: '/register',
    name: 'register',
    component: UserRegister
  },
  {
    path: '/',
    name: 'page',
    component: PublicPage
  },
  // {
  //   path: '/my-cards',
  //   name: 'Flashcards',
  //   component: FlashcardPage,
  //   beforeEnter: requireAuth 
  // },
  {
    path: '/my-account',
    name: 'My Account',
    component: AccountLayout,
    beforeEnter: requireAuth 
  },
  {
    path: '/complete-user',
    name: 'Complete User',
    component: ProfileCompletion,
    beforeEnter: requireAuth 
  },
  {
    path: '/reset-password',
    name: 'Reset Password',
    component: ResetPassword,
  },
  {
    path: '/checkout',
    name: 'Checkout',
    component: CheckoutPage,
    beforeEnter: requireAuth
  },
  {
    path: '/download',
    name: 'Download',
    component: DownloadPage,
    beforeEnter: requireAuth
  },
//   {
//     path: '/set',
//     name: 'Set',
//     component: FlashcardSet,
//     beforeEnter: requireAuth 
//   },
//   {
//     path: '/roteiro',
//     name: 'Roteiro',
//     component: MyRotes,
//     beforeEnter: requireAuth 
//   },
//   {
//     path: '/itineraries/:id',
//     name: 'Ver Roteiro',
//     component: ViewItinerary,
//     props: true,
//     beforeEnter: requireAuth 
//   },
//   {
//     path: '/area-do-aluno',
//     name: 'Area do aluno',
//     component: StudentArea,
//     props: true,
//     beforeEnter: requireAuth 
//   },
//   {
//     path: '/redacao',
//     name: 'Corrigir Redacao',
//     component: RedacaoVerify,
//     props: true,
//     beforeEnter: requireAuth 
//   },
// //{
// //  path: '/criar-redacao',
// //  name: 'Criar Redacao',
// //  component: CreateEssay,
// //    props: true,
// //    beforeEnter: requireAuth 
// //  },
//   {
//     path: '/listar-redacao',
//     name: 'Listar Redacao',
//     component: ListEssay,
//     props: true,
//     beforeEnter: requireAuth 
//   },
//   {
//     path: '/redacoes',
//     name: 'Lista de Redacoes',
//     component: EssayCatalog,
//     props: true,
//     beforeEnter: requireAuth 
//   },
//   {
//     path: '/folders/access/:token',
//     name: 'AccessSharedFolder',
//     component: AccessSharedFolder,
//     props: true,
//     beforeEnter: requireAuth 
//   }

  
];
const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
