<template>
  <div class="homepage">
    <HeaderComponent />
    <section class="banner">
      <div class="banner-content">
        <h1>Visto de nômade digital da Espanha</h1>
        <p>Saiba todos os detalhes de como eu consegui o visto de nômade digital da Espanha via consulado no Brasil</p>
      </div>
    </section>

    <!-- Seção do herói -->
    <section class="hero">
      <p>Adquira agora o guia completo e simplifique seu processo de obtenção do visto.</p>
      <div>
        <iframe width="100%" height="auto" style="aspect-ratio: 16/9;" src="https://www.youtube.com/embed/yVznmxWIbXg?si=O9oQoi6Erbge5VNa" frameborder="0" allowfullscreen></iframe>
      </div>
    </section>
    <!-- Seção de benefícios -->
    <section class="benefits">
      <h2>Que tipo de informação você encontra no PDF?</h2>
      <div class="benefits-grid">
        <div class="benefit">
          <h3>Passo a passo claro e direto</h3>
          <p>Economize tempo e evite frustrações com nosso guia detalhado.</p>
        </div>
        <div class="benefit">
          <h3>Modelos e formulários prontos</h3>
          <p>Disponibilizamos links para templates e formulários, facilitando o processo burocrático.</p>
        </div>
        <div class="benefit">
          <h3>Orientações valiosas</h3>
          <p>Receba insights úteis e baseados em experiências reais para enfrentar qualquer desafio durante a solicitação do visto.</p>
        </div>
      </div>
      <a href="/login" class="cta">Adquira agora</a>
    </section>
    <!-- Seção "Como fazer" -->
    <section class="howto">
      <div class="how">
        <h3>Veja o preview do documento</h3>
        <p>Aqui você encontrará todos os detalhes necessários para solicitar o visto via consulado.</p>
        <!-- Verifica se é um dispositivo móvel e exibe o botão correspondente -->
        <div v-if="isMobile">
          <button class="cta" @click="openPdfInNewTab">Pré-visualizar PDF</button>
        </div>
        <!-- Se não for um dispositivo móvel, exibe o PDF normalmente -->
        <object v-else
          width="100%"
          height="1000px"
          type="application/pdf"
          data="/pdf.pdf"
        ></object>
      </div>
      <div class="red-stripe"></div>

    </section>
    <!-- Seção de texto para SEO -->
    <section class="seo-text">
      <div class="seo-text-container">
        <h2>Descubra como obter o visto de nômade digital da Espanha de forma descomplicada</h2>
        <p>Você sonha em trabalhar remotamente enquanto desfruta das belas paisagens e da cultura vibrante da Espanha? O visto de nômade digital oferece a oportunidade perfeita para transformar esse sonho em realidade.</p>
        <p>Nosso guia abrangente oferece todos os detalhes necessários para facilitar o processo de obtenção do visto de nômade digital da Espanha. De preencher os formulários corretamente a obter os documentos essenciais, fornecemos um passo a passo claro e direto.</p>
        <p>Além disso, disponibilizamos links para templates e formulários, simplificando ainda mais o processo burocrático. Com orientações detalhadas e insights valiosos, você estará bem preparado para enfrentar qualquer desafio ao solicitar o seu visto.</p>
        <p>Assista ao vídeo explicativo para uma visão geral do processo e pré-visualize o documento para ter uma ideia do que esperar. Não perca a oportunidade de tornar seu sonho de viver e trabalhar na Espanha uma realidade. Adquira agora nosso guia completo e inicie sua jornada como nômade digital na ensolarada Espanha.</p>
      </div>
    </section>
  </div>
  <FooterComponent />
</template>

<script>
import { useMeta } from 'vue-meta';
import HeaderComponent from '@/components/HeaderComponent'
import FooterComponent from '@/components/FooterComponent'

FooterComponent.vue

export default {
  name: 'PublicPage',
  components: {
    HeaderComponent,
    FooterComponent,
  },
  setup() {
    // Adiciona o hook useMeta
    useMeta({
      title: 'Visto de nômade digital da Espanha',
      meta: [
        { charset: 'utf-8' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
        { name: 'description', content: 'Adquira agora o guia completo e simplifique seu processo de obtenção do visto de nômade digital da Espanha. Este guia oferece orientações valiosas e modelos prontos para facilitar sua solicitação de visto.' }
      ]
    });

    // Define a variável isMobile para verificar se é um dispositivo móvel
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    // Função para abrir o PDF em uma nova aba
    const openPdfInNewTab = () => {
      window.open('/pdf.pdf', '_blank');
    };

    return {
      isMobile,
      openPdfInNewTab
    };
  }
}
</script>
<style scoped>
.homepage {
  font-family: 'Poppins', sans-serif;
  margin: auto;
  color: #333;
}

.banner {
  background-image: url('visto.jpg'); /* Substitua pelo caminho da sua imagem de fundo */
  background-size: cover;
  background-position: center;
  height: 30vh; /* Define a altura do banner como 30% da altura da viewport */
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.banner-content {
  color: white; /* Cor do texto */
}
.banner h1 {
  font-size: 3rem; /* Tamanho do título */
  margin-bottom: 1rem; /* Espaçamento abaixo do título */
  padding: 5px; /* Adiciona preenchimento interno ao redor do título */
  background-color:  green; /* Cor de fundo verde */
  color: white; /* Cor da fonte branca */
  border-radius: 10px; /* Adiciona bordas arredondadas */
  opacity: 80%;
  display: inline-flex;

}

.banner p {
  font-size: 1.5rem; /* Tamanho da descrição */
  margin-bottom: 1rem; /* Espaçamento abaixo do título */
  padding: 5px; /* Adiciona preenchimento interno ao redor do título */
  color: rgb(255, 255, 255); /* Cor da fonte branca */


}
.hero {
  text-align: center;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;

}

.hero h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  font-family: 'Poppins', sans-serif;
}

.hero p {
  font-size: 1.25rem;
  margin-top: 1rem;
padding: 15px;
}

.cta {
  padding: 0.6rem 6rem;
  font-size: 1rem;
  background-color: #31762b;
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
  display: inline-block; /* Add this to prevent the button from breaking */
  text-align: center; /* Center the text inside the button */
  white-space: nowrap; /* Prevent text from wrapping to the next line */
  overflow: hidden; /* Hide any overflowing text */
  text-overflow: ellipsis; /* Display an ellipsis (...) for truncated text */
}


.cta:hover {
  background-color: #0056b3;
}
.howto{
  text-align: center;
  margin-bottom: 1.5rem;
  max-width: 1400px;
    margin: auto;

}
.benefits {
  text-align: center;
  background-color: #f1f0f0; /* Cor de fundo verde */
  color: #000000;
  padding-top: 20px;
  padding-bottom: 20px;
  max-width: 1000px;
  margin: auto;
  margin-bottom: 50px;
  
}

.benefits-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin: 30px;
  flex-direction: column;

}

.benefit {
  flex: 1 1; /* Tamanho mínimo e máximo de 200px */
  padding: 10px;
  background-color: rgb(227, 224, 224);
  border-radius: 10px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); /* Sombra leve */

}
.mobile-image {
  max-width: 100%;
  max-height: 100%;
}

.gif {
  max-width: 100%;
  max-height: 100%;
  margin-top: 1rem;
  margin-bottom: 1rem;
  background-color: #f5f5f8;

}

.mockup img{
  max-width: 100%;
  max-height: 100%;
  margin-left: 150px;
  margin-right: 160px;
}
.how {
  margin-left: 193px;
    margin-right: 193px;
    background-color: #f5f5f8;
    margin-bottom: 50px
  }
  .view {
    margin-left: 193px;
    margin-right: 193px;
    background-color: #f5f5f8;
    margin-bottom: 50px
  }

  .red-stripe {
  height: 20px;
  background-color: #ababaa; /* Cor de fundo verde */
}

.seo-text-container {
  max-width: 800px;
  margin: auto;
  padding: 0 20px;
  text-align: justify;
}
.seo-text-container h2 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}
.seo-text-container p {
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 1rem;
}

/* Estilos para dispositivos móveis */
@media (max-width: 600px) {

    .banner {
    height: auto; /* Altura automática para se adaptar ao conteúdo */
    padding: 20px; /* Adiciona espaço interno ao redor do banner */
    text-align: justify; /* Justifica o texto dentro do banner */
  }

  .banner h1 {
    font-size: 2rem; /* Reduz o tamanho do título para dispositivos móveis */
    margin-bottom: 10px; /* Aumenta o espaçamento abaixo do título */
  }

  .banner p {
    font-size: 1rem; /* Reduz o tamanho do texto para dispositivos móveis */
  }
  
  .hero h1 {
    font-size: 1.5rem;
  }
p {
    font-size: 0.9rem; }
  .cta {
    font-size: 0.9rem;
    width: 90%;
  }
  .benefits-grid {
    flex-direction: column;
    gap: 0px;
  }

  .benefit {
    width: 100%; /* Define a largura total */
    margin-bottom: 20px; /* Adiciona espaço entre os itens */
  }
  .cta {
    font-size: 0.9rem;
    width: 90%;
    margin-top: 2rem;
  }
  .how {
    margin: 15px;
    padding-top: 20px;
    margin-bottom: 70px;
  }
 
  .view {
    margin: 2px;
    margin-top: 32px;
    padding-top: 20px;
  }
  .mockup img{
  max-width: 100%;
  max-height: 100%;
}
  
  .mobile-image {
    margin-top: 1rem;
  }
}

</style>

