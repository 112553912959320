<template>
  <footer class="app-footer">
    <div class="footer-logo">
      <img src="@/assets/Front-logo-small.svg" alt="Footer Logo" />
    </div>

    <!-- Div para organizar o conteúdo do footer -->
    <div class="footer-content"> 
      <div class="footer-info"> <!-- Mantém o texto no centro -->
        <p>CNPJ: 45.836.900/0001-01</p> <!-- Substitua pelo seu CNPJ real -->
        <p>&copy; 2024 - Todos os direitos reservados</p> <!-- Adicionando ano e mensagem de copyright -->
      </div>
      <nav class="footer-nav"> <!-- Coloca a navegação à direita -->
        <ul v-if="isPublicPage" class="footer-links">
          <li><a href="/terms-of-service">Termos de Serviço</a></li>
          <li><a href="/privacy-policy">Política de Privacidade</a></li>
        </ul>
        <ul v-else class="footer-links">
          <li><a href="/contact">Contato</a></li> <!-- Alinhado à direita -->
        </ul>
      </nav>
    </div>
  </footer>
</template>

  <script>
  export default {
    name: 'FooterComponent',
    props: {
      isPublicPage: {
        type: Boolean,
        default: false
      }
    }
  }
  </script>
  
<style scoped>
.footer-logo {
  width: 40px;
  height: 25px;
}

.app-footer {
  font-family: 'Poppins', sans-serif;
  background-color: #ffffff;
  color: black;
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between; /* Alinha horizontalmente */
  align-items: center; /* Centraliza verticalmente */
  box-shadow: 0 -4px 12px rgba(0, 0, 0, 0.1);
}

.footer-content {
  display: flex;
  justify-content: space-between; /* Espaço entre elementos */
  align-items: center;
  width: 100%;
}

.footer-info {
  font-size: 0.9rem;
  color: black;
  text-align: center; /* Centraliza o texto na seção */
  flex: 1; /* Permite que esta seção ocupe todo o espaço do meio */
}

.footer-nav {
  display: flex;
  justify-content: flex-end; /* Mantém a navegação à direita */
}

.footer-links {
  display: flex;
  gap: 1rem;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.footer-nav a {
  color: black;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-nav a:hover {
  color: black;
  text-decoration: underline;
}

@media (max-width: 600px) {
  .app-footer {
    align-items: flex-start; /* Para dispositivos móveis */
    text-align: center; /* Garante alinhamento central */
  }

  .footer-nav {
    justify-content: center; /* Ajusta a navegação para o centro */
  }
  .footer-info {
  font-size: 0.5rem;
  color: black;
  text-align: center; /* Centraliza o texto na seção */
  flex: 1; /* Permite que esta seção ocupe todo o espaço do meio */
}
}
</style>