<template>
  <header class="app-header">
    <div class="logo">
      <img src="@/assets/Front-logo-small.svg" alt="Logo">
    </div>
    <nav class="header-nav">
      <ul v-if="isPublicPage" class="auth-list">
</ul>
 <ul v-else-if="isLoggedIn" class="auth-list">
      <!--- <li v-if="$route.path === '/my-cards'"><a href="/memo">Criar cards</a></li>
      <li v-else id="v-step-9"><a href="/my-cards">Meus cards</a></li> -->
      <li class="user-dropdown" @click="showDropdown = !showDropdown">
        <i class="fas fa-user-circle user-icon"></i>
        <span class="username-desktop">{{ displayUsername }}</span>
        <div v-if="showDropdown" class="dropdown-content">
          <a :href="'/my-account'">Minha conta</a>
          <a href="#" @click.prevent="handleLogout">Sair</a>
        </div>
      </li>
    </ul>

<ul v-else class="auth-list">
  <li><a href="/login">Login</a></li>
</ul>
    </nav>
  </header>
</template>
<script>
export default {
  name: 'HeaderComponent',
  props: {
    isPublicPage: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showDropdown: false,
    };
  },
  computed: {
    username() {
  return this.$store.state.username || localStorage.getItem('username'); 
},
isLoggedIn() {
  return !!localStorage.getItem('token'); // Supondo que você armazene um token quando o usuário faz login.
},
  displayUsername() {
    return this.username && this.username.length > 11 ? this.username.substring(0, 11) + '...' : this.username;
  }
},
  methods: {
    handleLogout() {
    // Limpa o username no Vuex
    this.$store.commit('setUsername', null); 

    // Remove o token do localStorage
    localStorage.removeItem('token');

    //Remove o Username do LocalStorage
    localStorage.removeItem('username');

    // Redireciona para a página de login
    this.$router.push('/login'); 
  }
}
}
</script>


<style scoped>

.logo {
  position: relative; /* Adicione position relative */
  width: 45px;
  height: 35px;
}
.app-header {
  font-family: 'Poppins', sans-serif;
  background-color: #ffff;
  color: white;
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-bottom: 4px solid green;
}

h1 {
  margin: 0;
  font-size: 1.8em;
}

.header-nav {
  display: flex;
  align-items: center;
}

.header-nav ul {
  display: flex;
  gap: 1rem;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.header-nav a {
  color: black;
  text-decoration: none;
  transition: color 0.3s ease;
}

.header-nav a:hover {
  color: black;
  text-decoration: none;
}

.auth-list {
  gap: 1rem;
  color: black;
}

.register-link {
  background-color: green;
  padding: 8px 16px;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.register-link:hover {
  background-color: green;
}

.header-nav .auth-list li:first-child a {
  background-color: green;
  padding: 8px 16px;
  border-radius: 4px;
  color: white;
  transition: background-color 0.3s ease;
}

.header-nav .auth-list li:first-child a:hover {
  background-color: green;
}

.underline-text {
  font-size: 16px;
  line-height: 1.5;

}
.username {
  text-decoration: underline;
}

@media (max-width: 600px) {
  .header-nav .auth-list li:first-child a {
  background-color: green;
  padding: 8px 23px;
  border-radius: 4px;
  color: white;
  transition: background-color 0.3s ease;
}
  }

  .v-tour__target--highlighted {
  box-shadow: 0 0 0 99999px rgba(0,0,0,.4);
}

.user-dropdown {
  position: relative;
}

.user-icon {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
}

.username-desktop {
  display: none; /* Escondido por padrão */
}

.dropdown-content {
  position: absolute;
  right: 0;
  top: 100%;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  z-index: 10;
}

.dropdown-content a {
  display: block;
  padding: 10px;
  color: black;
  text-decoration: none;
}

.dropdown-content a:hover {
  background-color: #f5f5f5;
}

/* Mostra o nome de usuário em telas maiores */
@media (min-width: 768px) {

}

.user-icon {
    font-size: 30px;
    cursor: pointer;
}

</style>