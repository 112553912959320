<template>
  <div class="account-layout">
    <HeaderComponent :isPublicPage="false" />
    <main class="account-main">
      <nav class="account-nav">
        <button @click="activeTab = 'personalInfo'" :class="{ 'active': activeTab === 'personalInfo' }">Meus dados</button>
      </nav>

      <div class="tab-content">
        <PersonalInfo v-if="activeTab === 'personalInfo'" />
      </div>
    </main>
  </div>
</template>

<script>
import HeaderComponent from "@/components/HeaderComponent.vue";
import PersonalInfo from "@/components/PersonalInfo.vue";

export default {
  name: "AccountLayout",
  components: {
    HeaderComponent,
    PersonalInfo,
  },
  data() {
    return {
      activeTab: 'personalInfo'
    };
  },
};
</script>

<style scoped>
.account-layout {
}

.account-header {
  text-align: center;
}

.account-nav {
  display: flex;
  gap: 20px;
  background-color: #007bff; /* Adicione esta linha para o fundo azul */
  padding: 10px 0; /* Adicione espaçamento interno para melhor visualização */
}

.nav-link {
  text-decoration: none;
  color: #ffffff; /* Cor do texto no fundo azul */
  font-weight: bold;
  transition: color 0.3s;
}

.nav-link:hover {
  color: #0056b3;
}

.account-main {
}

.account-nav {
  display: flex;
}

.account-nav button {
  background: none;
  border: none;
  color: #ffffff;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s;
  background-color: #0061c8;
}

.account-nav button.active {
  background-color: #0870df;
  margin-left: 3ch;
}

.tab-content {
  margin-top: 20px;
}

button {
  background-color: #007bff;
  padding: 8px 16px;
  border-radius: 4px;
  color: white;
  transition: background-color 0.3s ease;
}

</style>
