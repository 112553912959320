<template>
    <metainfo>
    <template v-slot:title="{ content }">{{ content ? `${content} | Nômade Digital` : `Nômade Digital` }}</template>
   </metainfo>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import { useMeta } from 'vue-meta'

export default {
  setup () {
    useMeta({
      title: 'Nômade Digital Visto',
      htmlAttrs: { lang: 'pt', amp: true }
    })
  }
}
</script>