<template>
  <div class="login-container">
    <h2>Login</h2>
    <form @submit.prevent="handleLogin">
      <div class="input-group">
        <label for="username">Usuário ou E-mail:</label>
        <input type="text" id="username" v-model="username" required>
      </div>

      <div class="input-group">
        <label for="password">Senha:</label>
        <input :type="showPassword ? 'text' : 'password'" id="password" v-model="password" required>
        <span @click="togglePassword">
          <i :class="showPassword ? 'fa fa-eye-slash' : 'fa fa-eye'"></i>
        </span>
        <a class="forgot-password" href="/reset-password">Esqueci minha senha</a>
      </div>

      <button type="submit">Continuar</button>
    </form>
    <button @click="goToRegister" class="register-button">Cadastre-se</button>
    <button @click="handleGoogleLogin" class="google-login-button">
      <img src="@/assets/g-logo.png" alt="Google Logo" class="google-logo">
      Login com Google
    </button>
  </div>
</template>

<script>
export default {
  name: 'UserLogin',
  data() {
    return {
      username: '',
      password: '',
      showPassword: false,
    };
  },
  mounted() {
  this.handleGoogleCallback();
  },
  methods: {
    handleGoogleCallback() {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    if (token) {
      localStorage.setItem('token', token);
      const preLoginRoute = localStorage.getItem('preLoginRoute') || '/checkout';
      localStorage.removeItem('preLoginRoute');
      this.$router.push(preLoginRoute);
    }
   },
    handleGoogleLogin() {
      const currentRoute = localStorage.getItem('preLoginRoute') || '/checkout';
      const googleAuthUrl = process.env.VUE_APP_GET_GOOGLE_AUTH_URL + `?redirect=${currentRoute}`;
      window.location.href = googleAuthUrl;
    },
    togglePassword() {
      this.showPassword = !this.showPassword;
    },
    async handleLogin() {
      const cleanedUsername = this.username.trim().toLowerCase();
      const cleanedPassword = this.password;

      try {
        const response = await fetch(process.env.VUE_APP_POST_LOGIN_API_URL, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            username: cleanedUsername,
            password: cleanedPassword,
          }),
        });

        const data = await response.json();

        if (response.status === 200) {
          localStorage.setItem('token', data.token);
          localStorage.setItem('username', cleanedUsername);
          this.$store.commit('setUsername', cleanedUsername);

          const preLoginRoute = localStorage.getItem('preLoginRoute') || '/checkout';
          localStorage.removeItem('preLoginRoute');
          this.$router.push(preLoginRoute);
        } else {
          alert(data.error);
        }
      } catch (error) {
        console.error('Erro ao fazer login:', error);
      }
    },
    goToRegister() {
      this.$router.push('/register');
    },
  },
};
</script>

  <style scoped>

.register-button {
    width: 100%;
    padding: 10px;
    margin-top: 20px;
    background-color: #6c757d;  /* um tom cinza, mas você pode alterar conforme necessário */
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .register-button:hover {
    background-color: #5a6268;  /* um tom cinza mais escuro para o hover */
  }
  .login-container {
  width: 500px; /* Mantenha a largura como estava no web */
  margin: 0 auto;
  padding: 50px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  text-align: center;
  }

h2 {
    text-align: center;
    margin-bottom: 30px;
    font-size: 24px;
    color: #333;
}

.input-group {
    margin-bottom: 20px;
}

label {
    display: block;
    margin-bottom: 10px;
    color: #555;
}

input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    color: #333;
    font-size: 16px;
    transition: border-color 0.3s ease;
}

input:focus {
    border-color: #007bff;
    outline: none;
}

button {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

button:hover {
    background-color: #0056b3;
}
.forgot-password {
  float: right;
  font-size: 14px;
  color: #333;
  text-decoration: none;
  transition: color 0.3s ease;
  margin: 2ch;
  text-decoration: underline;
}
.underline-text {
    font-size: 16px;
    line-height: 1.5;
  
  }
.forgot-password:hover {
  color: #000000;
}


.input-group {
    position: relative;
}

.input-group input[type="text"], .input-group input[type="password"] {
    padding-right: 40px; /* para garantir que o texto não seja coberto pelo ícone */
}

.input-group span {
    position: absolute;
    top: 50%; /* Centraliza verticalmente */
    right: 10px; /* Distância do ícone da borda direita do input */
    transform: translateY(-50%); /* Isso move o ícone de volta para cima pela metade de sua própria altura */
    cursor: pointer;
    z-index: 10;
}

.input-group i.fa {
    font-size: 15px; /* Ajuste conforme necessário */
    margin-top: 2.3rem;
    color: #6c757d;
}

.google-login-button {
    width: 100%;
    padding: 10px;
    margin-top: 20px;
    background-color: #fff;  /* Fundo branco */
    border: 1px solid #ccc;  /* Borda cinza */
    color: #757575;  /* Cor do texto cinza */
    border-radius: 4px;
    font-size: 18px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    gap: 15px;
}

.google-login-button:hover {
    background-color: #f7f7f7;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}

/* Adicione um ícone do Google ao botão */
.google-logo {
    width: 24px;  /* Defina a largura desejada para o logo */
    height: auto; /* Isso mantém a proporção correta */
    margin-right: 10px;  /* Espaço entre o ícone e o texto */
    display: inline-block;
    vertical-align: middle;
}

@media (max-width: 600px) {
  .login-container {
    width: 90%; /* Reduza a largura para dispositivos móveis */
    padding: 20px; /* Reduza o padding para dispositivos móveis */
  }

  /* Outros ajustes de estilo conforme necessário */
}
  </style>
