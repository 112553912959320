<template>
          <HeaderComponent />
    <div class="subscription-check">
      <h2>Confirmação de Assinatura</h2>
      <div v-if="isLoading" class="loading-message">
        <p>Verificando sua assinatura, por favor aguarde...</p>
      </div>
      <div v-if="!isLoading && !isSubscriber">
        <p>Seu status de assinatura ainda não foi confirmado.</p>
        <button @click="reloadPage" class="button1">Recarregar</button>
        <p>Se sua compra ainda não foi confirmada, tente novamente mais tarde ou clique abaixo para ir ao checkout.</p>
        <button @click="redirectToCheckout" class="button">Comprar PDF</button>
      </div>
      <div v-if="!isLoading && isSubscriber">
        <p>Você efetuou a compra do PDF! Clique em baixar.</p>
        <button @click="downloadPDF" class="button">Baixar PDF</button>
      </div>
      <div v-if="showToast" class="toast">
        {{ toastMessage }}
      </div>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  import HeaderComponent from '@/components/HeaderComponent.vue'; 

  
  export default {
    name: 'DownloadPage',
  components: {
    HeaderComponent,
  },
    data() {
      return {
        isSubscriber: false,
        isLoading: true,
        showToast: false,
        toastMessage: "",
      };
    },
    async mounted() {
      await this.checkSubscription();
    },
    methods: {
      async checkSubscription() {
        try {
          const response = await axios.get(process.env.VUE_APP_GET_ISSUBSCRIBER_URL, {
            headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
          });
          this.isSubscriber = response.data.isSubscriber;
        } catch (error) {
          console.error("Error checking subscription:", error);
          this.displayToast("Erro ao verificar assinatura. Tente novamente.");
        } finally {
          this.isLoading = false;
        }
      },
      reloadPage() {
        window.location.reload(); // Recarrega a página para tentar novamente
      },
      redirectToCheckout() {
        this.$router.push("/checkout"); // Redirecionar para a página de checkout
      },
      async downloadPDF() {
        try {
          const response = await axios({
            url: process.env.VUE_APP_DOWNLOAD_PDF_URL,
            method: "GET",
            headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
            responseType: "blob", // Necessário para downloads de arquivos
          });
  
          // Criar um link para baixar o arquivo
          const blob = new Blob([response.data], { type: "application/pdf" });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "watermarked.pdf"; // Nome do arquivo para download
          link.click(); // Iniciar o download
        } catch (error) {
          console.error("Erro ao baixar PDF:", error);
          this.displayToast("Erro ao baixar PDF. Tente novamente.");
        }
      },
      displayToast(message) {
        this.toastMessage = message;
        this.showToast = true;
        setTimeout(() => {
          this.showToast = false;
        }, 6000);
      },
    },
  };
  </script>
  
  <style scoped>
  .subscription-check {
    padding: 20px;
    text-align: center;
  }
  
  .loading-message {
    font-size: 16px;
  }
  
  .toast {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 10px;
    border-radius: 5px;
    z-index: 100;
  }


  .button1 {
  background-color: #0073ff;
  color: #fff;
  border: none;
  padding: 10px 80px;
  cursor: pointer;
  font-size: 14px;
  border-radius: 5px;
  margin-top: 1rem;
}

  .button {
  background-color:green;
  color: #fff;
  border: none;
  padding: 10px 80px;
  cursor: pointer;
  font-size: 14px;
  border-radius: 5px;
  margin-top: 1rem;
}
  </style>
  