import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import 'vuetify/styles'; // Importa os estilos do Vuetify
import { createVuetify } from 'vuetify'; // Importa a função de criação do Vuetify
import Vue3Tour from 'vue3-tour'
import 'vue3-tour/dist/vue3-tour.css';
import { createMetaManager } from 'vue-meta';



const app = createApp(App);

// Usa o Vue Router e Vuex (se necessário)
app.use(router);
app.use(store);
app.use(Vue3Tour);
app.use(createMetaManager());


const vuetify = createVuetify();
app.use(vuetify);
app.mount('#app');
