<template>
  <div class="account-settings">
    <h1>Complete o seu cadastro:</h1>
    <form class="form">
      <div class="input-group">
        <label>CPF:</label>
        <input v-model="cpf" @input="formatCPF" :class="{ 'invalid-input': !cpfIsValid }" />
        <span class="invalid-tooltip" v-if="!cpfIsValid">CPF inválido!</span>
      </div>
      <div class="input-group">
        <label>Telefone:</label>
        <input v-model="phone" @input="formatPhone" />
      </div>
      <div class="input-group">
        <label>Data de aniversário:</label>
        <input v-model="birthday" @input="formatBirthday" />
      </div>
      <button @click.prevent="saveChanges" class="save-button">Salvar</button>
    <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>
    </form>
  </div>
</template>


<script>
import axios from "axios";
import cpfCheck from "cpf-check";

export default {
  data() {
    return {
      cpf: '',
      phone: '',
      birthday: '',
      cpfIsValid: true,
      errorMessage: ''
    };
  },
  mounted() {
  },
  methods: {
    async saveChanges() {
      try {
        // Remove non-digit characters from CPF before sending to backend
        const formattedCPF = this.cpf.replace(/\D/g, "");

        await axios.put(process.env.VUE_APP_PUT_UPDATE_USER_API_URL, {
  CPF: formattedCPF, // Use the formatted CPF
  Phone: this.phone,
  Birthday: this.birthday
}, {
  headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
});
         // Exibindo mensagem de sucesso e redirecionando para a página /memo
         this.errorMessage = ""; // Limpar mensagens de erro anteriores
        this.$router.push("/memo");
      } catch (error) {
  console.error("Failed to update user data:", error);
  if (error.response && error.response.data && error.response.data.error) {
    this.errorMessage = error.response.data.error;
  } else {
    this.errorMessage = "Erro ao atualizar os dados do usuário.";
  }
}
    },
      formatCPF() {
  this.cpf = this.cpf.replace(/\D/g, ""); // Remove non-digit characters

  if (this.cpf.length > 11) {
    this.cpf = this.cpf.slice(0, 11);
  }

  // Validate CPF format (XXX.XXX.XXX-XX)
  if (this.cpf.length > 9) {
    this.cpf = `${this.cpf.slice(0, 3)}.${this.cpf.slice(3, 6)}.${this.cpf.slice(6, 9)}-${this.cpf.slice(9)}`;
  } else if (this.cpf.length > 6) {
    this.cpf = `${this.cpf.slice(0, 3)}.${this.cpf.slice(3, 6)}.${this.cpf.slice(6)}`;
  } else if (this.cpf.length > 3) {
    this.cpf = `${this.cpf.slice(0, 3)}.${this.cpf.slice(3)}`;
  }

  // Use cpfCheck to validate CPF
  const isValidCPF = cpfCheck.validate(this.cpf);
  this.cpfIsValid = isValidCPF; // Update cpfIsValid property

  if (!isValidCPF) {
    console.log("Invalid CPF");
    // You can show an error message or handle the invalid CPF as needed
  }
  },
  formatPhone() {
  this.phone = this.phone.replace(/\D/g, ""); // Remove non-digit characters

  if (this.phone.length > 11) {
    this.phone = this.phone.slice(0, 11);
  }

  // Format the phone number
  if (this.phone.length > 2) {
    this.phone = `(${this.phone.slice(0, 2)}) ${this.phone.slice(2)}`;
  }
  if (this.phone.length > 9) {
    this.phone = `${this.phone.slice(0, 9)}-${this.phone.slice(9)}`;
  }
},
formatBirthday() {
      this.birthday = this.birthday.replace(/\D/g, ""); // Remove non-digit characters

      if (this.birthday.length > 8) {
        this.birthday = this.birthday.slice(0, 8);
      }

      if (this.birthday.length > 4) {
        this.birthday = `${this.birthday.slice(0, 2)}/${this.birthday.slice(2, 4)}/${this.birthday.slice(4)}`;
      } else if (this.birthday.length > 2) {
        this.birthday = `${this.birthday.slice(0, 2)}/${this.birthday.slice(2)}`;
      }
    }
  }
};
</script>
  
  <style scoped>
  .account-settings {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
  
  .form {
    width: 500px;  /* Aumentei ligeiramente a largura para acomodar os campos adicionais */
    margin: 0 auto;
    padding: 50px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
  }
  
  .input-group {
    margin-bottom: 15px;
  }
  
  label {
    margin-bottom: 5px;
    display: block;
    font-family: 'Roboto', sans-serif;
  }
  
  input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    color: #333;
    font-size: 16px;
    transition: border-color 0.3s ease;
  }
  
  input:disabled {
    background-color: #ffffff;
    cursor: not-allowed;
  }
  
  .save-button {
    background-color: #007BFF;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .save-button:hover {
    background-color: #0056b3;
  }
  .invalid-input {
  border-color: red;
}

.invalid-tooltip {
  display: block;
  color: red;
  font-size: 14px;
  margin-top: 5px;
}
.edit-button {
  background-color: #f0ad4e;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 10px;
}

.edit-button:hover {
  background-color: #d58512;
}

.error-message {
  color: red;
  font-size: 14px;
  margin-top: 10px;
}
  
  </style>
  