<template>
  <div class="register-container">
      <h2>Registro</h2>
      <form @submit.prevent="handleRegister">
          <div class="input-group">
              <label for="fullname">Nome Completo:</label>
              <input type="text" id="fullname" v-model="fullname" required>
          </div>

          <div class="input-group">
              <label for="username">Usuário:</label>
              <input type="text" id="username" v-model="username" required>
          </div>
    
          <div class="input-group">
              <label for="email">Email:</label>
              <input type="email" id="email" v-model="email" required>
          </div>
    
          <div class="input-group">
    <label for="password">Senha:</label>
    <div class="password-wrapper">
        <input 
            :type="showPassword ? 'text' : 'password'" 
            id="password" 
            v-model="password" 
            @input="validatePassword"
            required
        >
        <!-- Ícone de olho para mostrar/esconder senha -->
        <span @click="togglePassword" class="toggle-icon">
            <i :class="showPassword ? 'fa fa-eye-slash' : 'fa fa-eye'"></i>
        </span>
    </div>
    <span v-if="passwordError" class="password-error">{{ passwordError }}</span>
</div>

<div class="input-group">
    <label for="confirmPassword">Confirme a Senha:</label>
    <div class="password-wrapper">
        <input 
            :type="showConfirmPassword ? 'text' : 'password'" 
            id="confirmPassword" 
            v-model="confirmPassword" 
            required
        >
        <!-- Ícone de olho para mostrar/esconder senha -->
        <span @click="toggleConfirmPassword" class="toggle-icon">
            <i :class="showConfirmPassword ? 'fa fa-eye-slash' : 'fa fa-eye'"></i>
        </span>
    </div>
</div>

    
          <button type="submit">Registrar</button>
      </form>
  </div>
</template>

<script>
export default {
  name: 'UserRegister',
  data() {
      return {
          fullname: '',
          username: '',
          email: '',
          password: '',
          confirmPassword: '',
          passwordError: '',
          showPassword: false,
        showConfirmPassword: false,
      };
  },
  methods: {
    togglePassword() {
        this.showPassword = !this.showPassword;
    },
    toggleConfirmPassword() {
        this.showConfirmPassword = !this.showConfirmPassword;
    },
      async handleRegister() {
          const usernameWords = this.username.trim().split(' ').slice(0, 1);
          this.username = usernameWords.join('');

          this.validatePassword();
          if (this.passwordError !== '') {
              alert(this.passwordError);
              return;
          }

          if (this.password !== this.confirmPassword) {
              alert("As senhas não coincidem.");
              return;
          }

          const cleanedUsername = this.username.trim().toLowerCase();
          try {
              const response = await fetch(process.env.VUE_APP_POST_REGISTER_API_URL, {
                  method: 'POST',
                  headers: {
                      'Content-Type': 'application/json'
                  },
                  body: JSON.stringify({
                      fullname: this.fullname,
                      username: cleanedUsername,
                      email: this.email,
                      password: this.password
                  })
              });

              const data = await response.json();

              if (response.status === 200) {
                  alert("Registro bem-sucedido!");
                  this.$router.push('/login');
              } else {
                  alert(data.error);
              }
          } catch (error) {
              console.error('Erro ao registrar:', error);
          }
      },
      validatePassword() {
          let errors = [];

          if (this.password.length < 8) {
              errors.push("a senha deve ter pelo menos 8 caracteres");
          }
          if (!/[a-z]/.test(this.password)) {
              errors.push("deve conter uma letra minúscula");
          }
          if (!/[A-Z]/.test(this.password)) {
              errors.push("deve conter uma letra maiúscula");
          }
          if (!/[0-9]/.test(this.password)) {
              errors.push("deve conter um número");
          }
          if (!/[!@#$%^&*()_+\-={}[\]:;"'|,.<>?]+/.test(this.password)) {
              errors.push("deve conter um caractere especial");
          }
          
          if (errors.length) {
              this.passwordError = "A senha " + errors.join(", ") + ".";
          } else {
              this.passwordError = '';
          }
      }
  }
}
</script>

  
  <style scoped>
.register-container {
  width: 500px; /* Mantenha a largura como estava no web */
  margin: 0 auto;
  padding: 50px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  text-align: center;
}

h2 {
    text-align: center;
    margin-bottom: 30px;
    font-size: 24px;
    color: #333;
}

.input-group {
    margin-bottom: 20px;
}

label {
    display: block;
    margin-bottom: 10px;
    color: #555;
}

input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    color: #333;
    font-size: 16px;
    transition: border-color 0.3s ease;
}

input:focus {
    border-color: #007bff;
    outline: none;
}

button {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

button:hover {
    background-color: #0056b3;
}

.password-error {
  display: block;
  color: red;
  font-size: 12px;
  margin-top: 5px;
}
.input-group {
    position: relative;
    margin-bottom: 20px;
}

.password-wrapper {
    position: relative;
    display: block;
}

.input-group input[type="text"], .input-group input[type="password"], .input-group input[type="email"] {
    width: 100%;
    padding-right: 40px; /* para garantir que o texto não seja coberto pelo ícone */
}

.toggle-icon {
    position: absolute;
    top: 50%; /* Centraliza verticalmente */
    right: 10px; /* Distância do ícone da borda direita do input */
    transform: translateY(-50%); /* Isso move o ícone de volta para cima pela metade de sua própria altura */
    cursor: pointer;
    z-index: 10;
}

.toggle-icon i.fa {
    font-size: 15px; /* Ajuste conforme necessário */
    color: #6c757d;
}


[v-tooltip]:hover::after {
  content: attr(v-tooltip);
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  z-index: 100;
  pointer-events: none;
}

@media (max-width: 600px) {
  .register-container {
    width: 90%; /* Reduza a largura para dispositivos móveis */
    padding: 20px; /* Reduza o padding para dispositivos móveis */
  }
}
  </style>
  