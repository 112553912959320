<template>
    <div class="account-settings">
      <form class="form">
        <div class="input-row">
          <div class="input-group">
            <label>Nome Completo:</label>
            <input v-model="fullName" :disabled="!isEditing" />
          </div>
          <div class="input-group">
            <label>Email:</label>
            <input v-model="email" :disabled="true" />
          </div>
        </div>
        <div class="input-row">
          <div class="input-group">
            <label>CPF:</label>
            <input v-model="cpf" :disabled="true" />
          </div>
          <div class="input-group">
            <label>Telefone:</label>
            <input v-model="phone" :disabled="!isEditing" />
          </div>
        </div>
        <div class="input-row">
          <div class="input-group">
            <label>Data de aniversário:</label>
            <input v-model="birthday" :disabled="!isEditing" />
          </div>
          <div class="input-group">
            <label>Usuário:</label>
            <input v-model="username" :disabled="true" />
          </div>
        </div>
        <div class="button-row">
          <button v-if="!isEditing" @click.prevent="startEditing" class="edit-button">Editar</button>
          <button v-if="isEditing" @click.prevent="saveChanges" class="save-button">Salvar</button>
        </div>
      </form>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  
  export default {
    data() {
      return {
        fullName: "",
        email: "",
        cpf: "",
        phone: "",
        birthday: "",
        username: "",
        isEditing: false // Track editing mode
      };
    },
    mounted() {
      this.fetchUserData();
    },
    methods: {
      async fetchUserData() {
        try {
          const response = await axios.get(process.env.VUE_APP_GET_USER_API_URL, {
            headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
          });
          const userData = response.data;
  
          this.fullName = userData.FullName;
          this.email = userData.Email;
          this.cpf = userData.CPF;
          this.phone = userData.Phone;
          this.birthday = userData.Birthday;
          this.username = userData.Username;
        } catch (error) {
          console.error("Failed to fetch user data:", error);
        }
      },
      startEditing() {
        this.isEditing = true;
      },
      async saveChanges() {
        try {
          const response = await axios.put(
            process.env.VUE_APP_PUT_UPDATE_USER_API_URL,
            {
              FullName: this.fullName,
              Phone: this.phone,
              Birthday: this.birthday
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
              }
            }
          );
  
          console.log("User data updated:", response.data);
          this.isEditing = false; // Exit editing mode
  
          // Optionally, you can show a success message or perform other actions
        } catch (error) {
          console.error("Failed to update user data:", error);
        }
      }
    }
  };
  </script>
  
  

  <style scoped>
  .account-settings {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
  
  form {
    width: 100%;
    max-width: 800px; /* Aumentando o valor para acomodar os campos lado a lado */
    margin: 0 auto; /* Centralizar o formulário na página */
  }
  
  .input-row {
    display: flex;
    justify-content: space-between; /* Espaçamento igual entre os campos */
    margin-bottom: 15px;
  }
  
  .input-group {
    width: calc(50% - 10px); /* Metade da largura, descontando o espaçamento */
  }
  
  label {
    margin-bottom: 5px;
    display: block;
    font-family: 'Roboto', sans-serif;
  }
  
  input {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    font-size: 16px;
    background-color: #ffffff;
  }
  
  input:disabled {
    background-color: #e3e1e1;
    cursor: not-allowed;
  }
  
  .save-button {
    background-color: #007BFF;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .save-button:hover {
    background-color: #0056b3;
  }

  .form{
    background-color: #f2f2f2;
    padding: 2ch;
    border-radius: 8px;
  }

  .edit-button {
    background-color: #007BFF;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
}


.button-row {
  display: flex;
  justify-content: flex-start;
}
  </style>
  